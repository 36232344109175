<template lang="html">
  <div class="gallery">
    <div class="flickity-primary-gallery">
      <flickity ref="primary" class="flickity-primary" :options="flickityPrimaryOptions">
        <div class="carousel-cell" v-for="image in images">
          <img :src="image.url" :key="image.id" />
        </div>
      </flickity>
    </div>

    <div class="flickity-secondary-gallery" v-if="images.length > 1">
      <flickity ref="secondary" class="flickity-secondary" :options="flickitySecondaryOptions">
        <div class="carousel-cell" v-for="image in images">
          <div class="carousel-image" :style="`background-image: url('${image.thumb_url}');`" :key="image.id"></div>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import asNavFor from 'flickity-as-nav-for';
import imagesLoaded from 'vue-images-loaded';

export default {
  props: ["images"],
  
  directives: {
    imagesLoaded
  },

  components: {
    Flickity,
  },

  data: function() {
    return {
      flickityPrimaryOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        draggable: false,
        imagesLoaded: true,
        percentPosition: false,
        adaptiveHeight: true
      },

      flickitySecondaryOptions: {
        pageDots: false,
        draggable: false,
        wrapAround: false,
        groupCells: false,
        initialIndex: 0,
        asNavFor: ".flickity-primary",
        imagesLoaded: true,
        cellAlign: 'left',
        percentPosition: false,
      }
    }
  }
}
</script>

<style lang="scss">

.gallery {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flickity-primary-gallery {
  width: 100%;
  max-width: 555px;

  .carousel-cell {
    width: 100%;
    height: 555px;

    @media screen and (max-width: 479px) {
      min-height: 350px;
    }
  }
}

.flickity-secondary-gallery {
  width: 424px;
  margin-top: -55px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  .flickity-secondary {
    width: 100%;

    @media screen and (max-width: 575px) {
      width: 100%;
      margin: 0 auto;

      .flickity-viewport {
        width: 75%;
        margin: 0 auto;
      }
    }
  }

  .carousel-cell {
    width: 128px;
    height: 128px;
    margin-right: 16px;
    cursor: pointer;

    @media screen and (max-width: 575px) {
      width: 88px;
      height: 88px;
    }

    .carousel-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(3,93,103,0.32);
      background-blend-mode: multiply;
    }

  }

  .flickity-button {
    background: transparent;
  }

  .flickity-button-icon {
    fill: #035D67;
  }

  .flickity-prev-next-button {
    top: 75%;
    &.previous {
      left: -60px;

      @media screen and (max-width: 575px) {
        left: 0;
      }
    }
    &.next {
      right: -60px;

      @media screen and (max-width: 575px) {
        right: 0;
      }
    }
  }
}
</style>