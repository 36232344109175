<template lang="html">
  <section class="articles__main">
    <div class="outer-wrapper">
      <div class="grid-wrapper">
        <ul class="articles__list">
          <li v-for="article in articles" class="articles__list__item">
            <div class="article-card">
              <div class="article-card__top" :style="`background-image: url('${article.image}');`" @click="goToArticle(article)">
                <div class="article-card__play-button">
                  <svg width="60px" height="60px" viewBox="0 0 60 60" id="play-button" v-if="article.video_url">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Home-Copy" transform="translate(-84.000000, -1207.000000)">
                        <g id="home--hero--play-copy" transform="translate(84.000000, 1207.000000)">
                          <g id="Group-6-Copy">
                            <g id="Group-4">
                              <g id="play-button__inner-circle" transform="translate(7.000000, 7.000000)" fill="#D3B98A" opacity="0.495606884">
                                <rect id="Rectangle-5" x="0" y="0" width="46" height="46" rx="23"></rect>
                              </g>
                              <g id="play-button__outer-circle" opacity="0.176766304" stroke="#D3B98A">
                                <rect id="Rectangle-5" x="0.5" y="0.5" width="59" height="59" rx="29.5"></rect>
                              </g>
                              <g id="Group-3" transform="translate(24.000000, 21.000000)" fill="#FFFFFF">
                                <path d="M17.1801724,8.7256803 L2.31979561,0.248437917 C2.0325658,0.0834470203 1.73338723,0 1.43614905,0 C0.604382585,0 0,0.629639519 0,1.49629809 L0,18.5037019 C0,19.370409 0.604382585,20 1.43614905,20 C1.73534805,20 2.0325658,19.9146595 2.31979561,19.7515621 L17.1801724,11.2743197 C17.7007605,10.9765748 17.9999901,10.5119456 17.9999901,9.99799976 C18.0019816,9.48784076 17.7027877,9.02322126 17.1801724,8.72546668 L17.1801724,8.7256803 Z M2,17.5555556 L2,2 L15.5,9.77777778 L2,17.5555556 Z" id="Fill-2"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="article-card__bottom">
                <h3 class="article-card__title">{{ article.title }}</h3>
                <div class="article-card__excerpt" v-html="article.body" />
                <a :href="article.url" class="button button--purple">{{ article.video_url ? 'Watch' : 'Read more' }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="articles__list__load-more" v-if="hasMore">
        <button class="button button--dark-green" @click="fetchMore">More Articles</button>
      </div>
    </div>
  </section>
</template>

<script>
import Api from "../utils/api";

export default {
  props: [
    "articleType",
  ],
  data() {
    return {
      articles: [],
      offset: 0,
      limit: 12,
      loading: true,
      hasMore: false,
    }
  },
  methods: {

    load() {
      console.log("this.articleType", this.articleType)
      this.loading = true
      this.offset = 0
      this.hasMore = false
      Api.get('/articles', { params: { 
        offset: 0,
        limit: this.limit,
        article_type: this.articleType
      }})
        .then((response) => {
          this.articles = response.data.articles
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });
    },

    fetchMore() {
      this.offset++
      Api.get('/articles', { params: { 
        offset: this.offset,
        limit: this.limit,
        article_type: this.articleType
      }})
        .then((response) => {
          this.articles = [...this.articles, ...response.data.articles]
          this.hasMore = response.data.has_more
          this.loading = false
        }).catch((e) => {
          this.loading = false;
          console.log(e.response)
          alert("Sorry, something went wrong")
        });      
    },

    goToArticle(article) {
      window.location = article.url
    }
  },

  mounted() {
    this.load()
  }
}
</script>