<template lang="html">

  <form class="form form--contact" @submit="onSubmit">
    <p>Or leave your details and we'll give you a call back:</p>
    <div class="form__row">
      <div class="form__field">
        <label for="name">Name</label>
        <input type="text" id="name" name="name" required value="" placeholder="Your name" v-model="enquiry.name">
        <div class="error" v-if="errors.name">{{ errors.name }}</div>
      </div>
    </div>

    <div class="form__row">
      <div class="form__field">
        <label for="tel">Telephone Number</label>

        <input type="tel" id="tel" name="tel" value="" required placeholder="Your phone number" v-model="enquiry.telephone">
        <div class="error" v-if="errors.telephone">{{ errors.telephone }}</div>
      </div>
    </div>

    <div class="form__row">
      <div class="form__field">
        <label for="email">E-mail</label>

        <input type="email" id="email" name="email" value="" required placeholder="Your e-mail address" v-model="enquiry.email">
        <div class="error" v-if="errors.email">{{ errors.email }}</div>
      </div>
    </div>    

    <div class="form__row">
      <div class="form__field">
        <label for="enquiry">Your Enquiry</label>
        <textarea name="enquiry" id="enquiry" rows="10" cols="80" placeholder="Your enquiry" v-model="enquiry.message" required></textarea>
        <div class="error" v-if="errors.message">{{ errors.message }}</div>
      </div>
    </div>

    <div class="form__submit">
      <button type="submit" name="submit" :disabled="isSending" class="button button--light-green">Submit Enquiry</button>
      <transition name="success">
        <span class="success-message" v-if="success">Your message has been sent</span>
      </transition>
    </div>
  </form>

</template>

<script>
import Api from "../utils/api";

export default {

  props: [
    'category',
    'title', 
    'date',
    'location', 
    'image',
    'url'
  ],

  data: function() {
    return {
      enquiry: {
        name: '',
        telephone: '',
        email: '',
        message: '',
      },
      errors: {},
      success: false,
      isSending: false,
      faded: false
    }
  },
  
  methods: {
	  clearForm() {
			for (let field in this.enquiry) {
				this.enquiry[field] = ''
      }
      this.errors = {}
		},

		onSubmit(evt) {
			evt.preventDefault();

      this.isSending = true;
      
      Api.post('/enquiries', { enquiry: this.enquiry })
        .then((response) => {
          this.clearForm();
          this.isSending = false;
          this.success = true;
          this.faded = true;

          // Google Adwords Conversion
          // Event snippet for Contact Us Form Sent conversion page 
          gtag('event', 'conversion', {'send_to': 'AW-964383798/LFLxCNTQzZIZELao7csD'});

          setTimeout(() => {
            this.success = false;
            this.faded = false;
          }, 4000);
        }).catch((e) => {
          this.isSending = false;
          console.log(e.response)
          if (e.response && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Sorry, something went wrong, please try again.")
          }
        });
	  	}
	}  
}
</script>

<style lang="scss">
  .success-message {
    padding-left: 20px;
    color: #5E285F;
  }

  .success-enter-active, .success-leave-active {
    transition: opacity .5s;
  }
  
  .success-enter, .success-leave-to {
    opacity: 0;
  }
</style>