<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-close" @click="$emit('close')"></div>
          <div class="modal-inner-container">
            <div class="modal-left">
              <div class="modal-image">
                <img v-for="image in extra.images" :src="image.thumb_url" :alt="image.alt" :key="image.id" />                
              </div>
            </div>

            <div class="modal-right">
              <div class="modal-product-name">
                <h3>{{ extra.name }}</h3>
              </div>

              <div class="modal-product-description" v-html="extra.body">
              </div>
              <div v-if="extra.data_sheet_url">
                <a :href="extra.data_sheet_url" class="modal-product-datasheet" target="_blank">Download Data Sheet</a>
              </div>

              <div class="modal-product-notice">
                <p>Must be purchased in conjunction with with another green roof product.</p>
              </div>

              <div class="modal-product-calculator" v-if="hasCalc">
                <div class="calculator">
                  <div class="calculator__container">
                    <div class="calculator__left">
                      <div class="calculator__quantity">
                        <span id="calculator_decrement" class="calculator__quantity__minus"
                          v-on:click="decrement()">-</span>

                        <div class="calculator__quantity__input">
                          <input id="calculator_quantity" type="number" min="0" pattern="\d+" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" step="1" name="quantity"  v-model.number="quantity" >
                        </div>

                        <span id="calculator_increment" class="calculator__quantity__plus"
                          v-on:click="increment()">+</span>
                      </div>
                      <div class="calculator__total">
                        <p><span>{{ extra.unit }}</span> = <span>{{ price | currency(currencySymbol) }}</span> &plus; VAT</p>
                      </div>
                    </div>
                    <div class="calculator__buy">
                      <button class="button button--purple" type="button" @click="buy" name="buy">Add To Cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Api from "../utils/api";
import EventBus from '../utils/event-bus';

export default {
  props: {
    currencySymbol: {
      type: String,
      default: '£'
    },
    extra: {
      type: Object,
      default: {},
    },
    hasCalc: {
      type: Boolean,
      default: true,
    },
  },

  name: 'Modal',

  data: function() {
    return {
      quantity: 0,
    }
  },

  computed: {
    price: function() {
      return parseFloat(this.extra.price) * this.quantity;
    },
  },
    
  methods: {
    increment: function() {
      this.quantity += 1;
    },
    decrement: function() {
      this.quantity -= 1;
      if (this.quantity < 0) {
        this.quantity = 0;
      }
    },
    buy: function() {
      if (this.quantity > 0) {
        Api.post('/basket/add', {  
          product_id: this.extra.id,
          quantity: this.quantity,
        })
        .then(() => {
          EventBus.$emit('update_basket');
          this.$emit('close')
        })
        .catch((e) => {
          console.log(e)
          alert("ERROR", e)
        })  
      }
    },
  }
}
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  

  @media screen and (max-width: 479px) {
    padding: 20px;
    height: 100vh;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  @media screen and (max-width: 479px) {
    vertical-align: top;
  }
}

.modal-close {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 26px;
  height: 26px;
  background: url('../../assets/images/icons/icon--close.svg') no-repeat;
  cursor: pointer;

  @media screen and (max-width: 479px) {
    top: 10px;
    right: 10px;
  }
}

.modal-container {
  position: relative;
  width: 55%;
  max-width: 786px;
  margin: 0px auto;
  background-color: #F5F4F1;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: 'museo', sans-serif;

  @media screen and (max-width: 1140px) {
    width: 80%;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    border-radius: 6px;
    height: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }
}

.modal-inner-container {
  display: flex;
  padding: 42px;

  @media screen and (max-width: 479px) {
    padding: 40px 20px;
    flex-direction: column-reverse;
  }
}

.modal-left {
  flex: 0 0 25%;
  margin-right: 20px;

  @media screen and (max-width: 479px) {
    margin-right: 0;
    margin-top: 40px;
  }
}

.modal-right {
  flex: 0 0 75%;
}

.modal-image {
  img {
    border-radius: 5px;
    width: 100%;
    max-width: 160px;
    height: auto;
  }

  & > * + * {
    margin-top: 35px;
  }

  @media screen and (max-width: 479px) {
    display: flex;
    flex-wrap: wrap;
    
    & > * + * {
      margin-top: 0;
    }

    img {
      flex: 0 0 25%;
      max-width: 165px;
      max-height: 165px;
      margin-top: 12px;
      &:not(:last-of-type) {
        margin-right: 12px;
      }
    }
  }
}

.modal-product-name {
  margin-bottom: 20px;

  h3 {
    font-size: 2.5rem;
    color: #303433;
    letter-spacing: 0;
    line-height: 1.15;
  }
  @media screen and (max-width: 479px) {
    margin-bottom: 12px;
    h3 {
      font-size: 1.825rem;
      line-height: 1.2;
    }
  }
}

.modal-product-datasheet {
      font-weight: normal;
  font-size: 0.9375rem;
  color: #303433;
  letter-spacing: 0;
  line-height: 1.86;
  margin-bottom: 15px;
  border-bottom: 1px solid #5E285F;
  &:hover {
    color: #5E285F;
  }
}

.modal-product-description {
  p {
    font-weight: bold;
    font-size: 0.9375rem;
    color: #303433;
    letter-spacing: 0;
    line-height: 1.86;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 8px;

    li {
      font-weight: normal;
      font-size: 0.9375rem;
      color: #303433;
      letter-spacing: 0;
      line-height: 1.86;
    }
  }
}

.modal-product-notice {
  p {
    font-weight: bold;
    font-size: 0.9375rem;
    color: #303433;
    letter-spacing: 0;
    line-height: 1.86;
    margin-bottom: 15px;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>