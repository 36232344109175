<template lang="html">
  <div class="mobile-gallery">
    <div class="mobile-primary-gallery">
      <flickity ref="primary" class="mobile-primary" :options="flickityPrimaryOptions">
        <div class="carousel-cell" v-for="image in images">
          <img :src="image.url" :key="image.id" />
        </div>
      </flickity>
    </div>
    <div class="mobile-secondary-gallery">
      <flickity ref="secondary" class="mobile-secondary" :options="flickitySecondaryOptions">
        <div class="carousel-cell" v-for="image in images">
          <div class="carousel-image" :style="`background-image: url('${image.thumb_url}');`" :key="image.id"></div>
        </div>
      </flickity>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import asNavFor from 'flickity-as-nav-for';
import imagesLoaded from 'vue-images-loaded';

export default {
  props: ["images"],
  
  directives: {
    imagesLoaded
  },

  components: {
    Flickity,
  },

  data: function() {
    return {
      flickityPrimaryOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        draggable: true,
        imagesLoaded: true,
        percentPosition: false,
        adaptiveHeight: true
      },

      flickitySecondaryOptions: {
        pageDots: false,
        draggable: true,
        wrapAround: false,
        groupCells: false,
        initialIndex: 0,
        asNavFor: ".mobile-primary",
        imagesLoaded: true,
        cellAlign: 'left',
        percentPosition: false,
      }
    }
  }
}
</script>

<style lang="scss">

.mobile-gallery {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-primary-gallery {
  width: 100%;
  max-width: 555px;
  margin-bottom: 40px;

  @media screen and (max-width: 420px) {
    margin-bottom: 20px;
  }

  .flickity-slider {
    border-radius: 5px;
  }

  .carousel-cell {
    width: 100%;
    max-height: 555px;
    min-height: 475px;

    @media screen and (max-width: 479px) {
      min-height: 400px;
    }

    @media screen and (max-width: 375px) {
      min-height: 320px;
    }
  }
}

.mobile-secondary-gallery {
  width: 424px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  .mobile-secondary {
    width: 100%;

    @media screen and (max-width: 575px) {
      width: 100%;
      margin: 0 auto;

      .flickity-viewport {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .carousel-cell {
    width: 128px;
    height: 128px;
    margin-right: 20px;
    cursor: pointer;

    @media screen and (max-width: 575px) {
      width: 30%;
      height: 0;
      padding-top: 30%;
      &:not(:last-of-type) {
        margin-right: 5%;
      }
    }

    .carousel-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(3,93,103,0.32);
      background-blend-mode: multiply;
    }
  }

  .flickity-button {
    background: transparent;
  }

  .flickity-button-icon {
    fill: #035D67;
  }

  .flickity-prev-next-button {
    top: 50%;

    @media screen and (max-width: 420px) {
      width: 30px;
      height: 30px;
    }

    &.previous {
      left: -60px;

      @media screen and (max-width: 575px) {
        left: 0;
      }
    }
    &.next {
      right: -60px;

      @media screen and (max-width: 575px) {
        right: 0;
      }
    }
  }
}
</style>