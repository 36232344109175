<template>
  <section class="buy__split" v-if="product && product.stats">
    <div class="module__split">
      <div class="split__container split__container--image split__container--image--bordered border--blue cut-same-day"></div>

      <div class="split__container split__container--text">
        <div class="split__container__text-content split__container__text-content--with-heading">
          <h2 style="font-size:1.5em;line-height:1.5em;">
            Cut to order.<br/>
            <!-- Shipped Same day.<br /> -->
            Easy to install.
          </h2>
          <!-- <p>Freshness is important, so our Sedum Blanket is cut and delivered to order. Order before 10am Wednesday for same week delivery, or let us know when you need it and we’ll make sure it’s there in time for fitting.</p> -->
          <p>Every kit includes everything you need to get the job done. Our 1m wide rolls are easily lifted by 2 people. Our special blend of growing medium is bagged for easy installation.</p>
        </div>
      </div>
    </div>

    <div class="module__split module__split--reversed module__split--tall" v-if="product && product.stats">
      <div class="split__container split__container--image split__container--image--bordered border--green features"></div>

      <div class="split__container split__container--text">
        <div class="split__container__stats">
        <p class="split__container__stats__heading">Features</p>
          <ul class="grid grid--stats">
            <li class="stat--1">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--layers"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.drainage_layers}}</p>
                  <p class="stat__info__text">piece drainage layers</p>
                </div>
              </div>
            </li>

            <li class="stat--2">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--substrate"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.depth_of_substrate}}</p>
                  <p class="stat__info__text">mm depth of substrate</p>
                </div>
              </div>
            </li>

            <li class="stat--3">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--birthday"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.year_minimum_matured_sedum}}</p>
                  <p class="stat__info__text">year minimum matured sedum</p>
                </div>
              </div>
            </li>

            <li class="stat--4">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--coverage"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.minimum_coverage}}&#37;</p>
                  <p class="stat__info__text">minimum coverage</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="module__split module__split--tall">
      <div class="split__container split__container--image split__container--image--bordered border--grey hardened-with"></div>

      <div class="split__container split__container--text">
        <div class="split__container__stats">
          <p class="split__container__stats__heading">Hardened with</p>
          <ul class="grid grid--stats">
            <li class="stat--1">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--water-retention"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.days_of_sea_salt_exposure}}</p>
                  <p class="stat__info__text">days of sea salt exposure</p>
                </div>
              </div>
            </li>

            <li class="stat--2">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--rain"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.mm_of_rain_water}}</p>
                  <p class="stat__info__text">mm of rain water</p>
                </div>
              </div>
            </li>

            <li class="stat--3">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--seaweed"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.ltrs_of_organic_seaweed_fertiliser}}</p>
                  <p class="stat__info__text">ltrs of organic seaweed fertiliser*</p>
                </div>
              </div>
            </li>

            <li class="stat--4">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--mineral-water"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.lltrs_sustainably_sourced_mineral_water}}</p>
                  <p class="stat__info__text">ltrs of sustainably sourced mineral water&dagger;</p>
                </div>
              </div>
            </li>

            <li class="stat--5">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--wind"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.mph_winds}}</p>
                  <p class="stat__info__text">mph winds</p>
                </div>
              </div>
            </li>

            <li class="stat--6">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--naturally-cooling"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.days_frost_or_snow}}</p>
                  <p class="stat__info__text">days frost or snow</p>
                </div>
              </div>
            </li>
          </ul>

          <div class="split__container__stats__footnotes">
            <p>* We make our own seaweed fertiliser.</p>
            <p>&dagger; We have our own well that draws water from the limestone aquifer under the fields the sedum grows on.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="module__split module__split--reversed module__split--tall">
      <div class="split__container split__container--image split__container--image--bordered border--sand benefits"></div>

      <div class="split__container split__container--text">
        <div class="split__container__stats">
          <p class="split__container__stats__heading">Benefits</p>
          <ul class="grid grid--stats">
            <li class="stat--1">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--water-retention"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.reduction_precipitation_runoff}}</p>
                  <p class="stat__info__text">reduction precipitation runoff</p>
                </div>
              </div>
            </li>

            <li class="stat--2">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--heating-costs"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.insultation_level}}</p>
                  <p class="stat__info__text">insulation level</p>
                </div>
              </div>
            </li>

            <li class="stat--3">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--sound-insulation"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.db_sound_reduction}}</p>
                  <p class="stat__info__text">dB sound reduction</p>
                </div>
              </div>
            </li>

            <li class="stat--4">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--carbon-dioxide"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.carbon_reduction}}</p>
                  <p class="stat__info__text">g carbon reduction /metre sq</p>
                </div>
              </div>
            </li>

            <li class="stat--5">
              <div class="stat">
                <div class="stat__icon">
                  <i class="icon icon--insect"></i>
                </div>
                <div class="stat__info">
                  <p class="stat__info__number">{{product.stats.increase_in_insects}}</p>
                  <p class="stat__info__text">increase in insects /metre sq</p>
                </div>
              </div>
            </li>
          </ul>

          <div class="split__container__stats__footnotes" v-if="product.data_sheet_url">
            <a :href="product.data_sheet_url" class="button button--light-green button--download button--download--white" target="_blank" v-html="`Download ${product.name} Data Sheet`"></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    "product"
  ],
}
</script>