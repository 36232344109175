<template>
  <li class="faq__list__item">
    <div ref="question" class="question" @click="expand">
      <span class="symbol"></span>
      <p>{{ question }}</p>
    </div>
    
    <div ref="answer" class="answer" v-html="answer"></div>
  </li>
</template>

<script>
export default {
  props: [
    "question",
    "answer"
  ],

  data: function() {
    return {
      height: null,
      expanded: false
    }
  },

  methods: {
    getHeight() {
      let answer = this.$refs.answer;
      let height = answer.scrollHeight;
      this.height = height + 'px';
    },

    onResize(event) {
      this.getHeight();
    },

    expand: function() {
      let question = this.$refs.question;
      let answer = this.$refs.answer;
      if(!this.expanded) {
        question.classList.add('open');
        answer.style.maxHeight = this.height;
        this.expanded = true;
      } else {
        question.classList.remove('open');
        answer.style.maxHeight = 0;
        this.expanded = false;
      }
    }
  },

  mounted: function() {
    this.getHeight();
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy() {
  // Unregister the event listener before destroying this Vue instance
  window.removeEventListener('resize', this.onResize)
}
}
</script>

<style lang="scss">
  .question {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .symbol {
      display: inline-block;
      width: 22px;
      height: 22px;
      position: relative;
      margin-right: 12px;
      flex-shrink: 0;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        width: 2px;
        height: 22px;
        background-color: #B3B3B6;
        position: absolute;
        transition: all .5s;
      }

      &::before {
        left: 9px;
        top: 0;
      }

      &::after {
        left: 21px;
        top: 10px;
        transform-origin: 0% 0%;
        transform: rotate(90deg);
      }
    }

    p {
      font-weight: bold;
      font-size: 1.1875em;
      line-height: 1.3;
      flex-shrink: 1;

      @media screen and (max-width: 479px) {
        font-size: 0.9375em;
        line-height: normal;
      }
    }

    &.open {
      .symbol {
        &::before {
          transform: rotate(90deg);
        }

        &::after {
          opacity: 0;
        }
      }
    }
  }

  .answer {
    height: auto;
    max-height: 0;
    overflow: hidden;
    padding-left: 34px;
    margin-bottom: 12px;
    transition: all .5s ease-in-out;

    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
</style>