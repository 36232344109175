<template v-if="videoModalVisible">
  <transition name="modal">
    <div class="video-modal-mask">
      <div class="video-modal-wrapper">
        <div class="video-modal-close" @click="$emit('close')"></div>
        <div class="video-modal-container">
          <div class='embed-container'><iframe src='https://player.vimeo.com/video/232667613' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.video-modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  opacity: 1;
  display: table;
  transition: opacity .5s ease;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}

.video-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.video-modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  background: url('../../assets/images/icons/icon--modal-close.svg') no-repeat;
  background-size: 95%;
  cursor: pointer;
  z-index: 9998;

  @media screen and (max-width: 768px) {
    top: 16px;
    right: 16px;
  }
}

.video-modal-container {
  width: 75%;
  margin: 0 auto;
  transition: all .5s ease-in-out;

  @media screen and (max-width: 940px) {
    width: 90%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.embed-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
} 

.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .video-modal-container,
.modal-leave-active .video-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>