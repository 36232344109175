<template>
  <div v-if="visible">
    <a href="/checkout">
      <div class="c-shopping-cart">
        <div class="c-shopping-cart__container">
          <span class="check-out">Check Out</span>
          <span class="quantity">{{ quantity }}</span>
        </div>
      </div>

      <div class="c-mobile-shopping-cart">
        <div class="c-mobile-shopping-cart__container">
          <span class="quantity">{{ quantity }}</span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import Api from "../utils/api";
import EventBus from '../utils/event-bus';

export default {
  props: {
    loadquantity: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      quantity: this.loadquantity,
      visible: this.loadquantity > 0
    }
  },

  mounted: function() {
    var shoppingCart = this;
    
    EventBus.$on('update_basket', (payLoad) => {
      shoppingCart.updateBasket()
    });    
    EventBus.$on('update_quantity', (qty) => {
      this.quantity = qty
    })
  },

  methods: {
    updateBasket: function() {
      Api.get('/quantity')
        .then(response => {
          this.quantity = response.data && response.data.quantity ? response.data.quantity : 0;
          this.checkForQuantity();
        })
        .catch(e => console.log("error retrieving basket", e))
    },
    checkForQuantity: function() {
      if (this.quantity > 0) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    }  
  }
}
</script>

<style lang="scss" scoped>
  .c-shopping-cart {
    display: inline-block;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &__container {
      position: relative;
      display: inline-block; 
      padding: 12px 54px 12px 24px;
      border-radius: 100px;
      color: #FFF;
      background-color: #5E285F;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      background-image: url('../../assets/images/icons/icon--shopping-cart.svg');
      background-repeat: no-repeat;
      background-position: right 20px center;
    }

    .quantity {
      font-weight: bold;
      font-size: 0.75rem;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      top: 10px;
      right: 30px;
    }
  }

  .c-mobile-shopping-cart {
    display: none;

    @media screen and (max-width: 768px) {
      display: inline-block;
      
      &__container {
        position: relative;
        width: 32px;
        height: 32px;
        background-image: url('../../assets/images/icons/icon--shopping-cart--purple.svg');
        background-repeat: no-repeat;
        background-size: 32px 31px;
        background-position: center;
      }
    }

    .quantity {
      font-weight: bold;
      font-size: 0.75rem;
      color: #5E285F;
      text-align: center;
      position: absolute;
      top: 5px;
      left: 10px;
    }
  }
</style>