<template lang="html">
  <section class="extras" id="extras-modal">
    <div class="outer-wrapper">
      <div class="extras__container">
        <h3 class="extras__heading">Optional Extras</h3>

        <ul class="extras__list grid grid--optional-extras">
          <li v-for="(extra, index) in extras" :class="`extras__list__item grid__item grid__item--${index + 1}`">
            <div class="extra" @click="prepModal(extra)">
              <div class="extra__image" v-if="extra.images.length > 0" :style="`background-image: url('${extra.images[0].thumb_url}')`"></div>
              <div class="extra__info">
                <h4>{{ extra.name }}</h4>
                <p>{{ extra.price | currency(currencySymbol) }} &plus; VAT /{{ extra.unit }}</p>
                <a>View details</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <modal v-if="showModal" @close="showModal = false" :extra="modalExtra" :currencySymbol="currencySymbol">
    </modal>

  </section>
</template>

<script>
import Modal from './modal.vue'

export default {
  components: {
    Modal
  },

  props: {
    extras: {
      type: Array,
      default: function() {
        return []
      }
    },
    currencySymbol: {
      type: String,
      default: '£'
    }
  },

  data: function () {
    return {
      showModal: false,
      modalExtra: {}
    }
  },

  methods: {
    prepModal: function(extra) {
      this.modalExtra = extra;
      this.showModal = true
    }
  }  

}
</script>

<style lang="scss">
.extras {
  background: #F9F9F7;

  &__container {
    padding: 20px 0 40px;
  }

  &__heading {
    font-weight: bold;
    font-size: 1.25rem;
    color: #035D67;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 28px;
  }
}

.extra {
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__image {
    width: 128px;
    height: 128px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 28px;
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 375px) {
      margin-right: 0;
      margin-bottom: 28px;
    }
  }

  &__info {
    h4 {
      font-size: 0.9375rem;
      line-height: 1.33;
    }

    p {
      font-weight: 500;
      font-size: 1rem;
      color: #5E285F;
      line-height: 2;
      margin-bottom: 18px;

      @media screen and (max-width: 375px) {
        margin-bottom: 0;
      }
    }

    a {
      cursor: pointer;
      font-weight: 300;
      font-size: 0.8125rem;
      line-height: 2.15;
      border-bottom: 1px solid #5E285F;
    }
  }
}
</style>
